<template>
    <div class="search-placeholder">
        <div class="search-placeholder__label">Search for</div>
        <div class="search-placeholder__indexes">
            <ul>
                <template v-for="i in 6" :key="i">
                    <li>devices</li>
                    <li>experts</li>
                    <li>institutes</li>
                    <li>procedures</li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="less">
.search-placeholder {
    position: absolute;
    top: 50%;
    left: var(--spacing-sm);
    transform: translateY(-50%);

    display: flex;
    height: 100%;
    align-items: center;
    gap: .25em;
    color: var(--color-secondary);
    font-size: .875rem;
    overflow: hidden;
    pointer-events: none;
}

.base-input[data-focus='true'] + .search-placeholder {
    display: none;
}

.search-placeholder__label {
    opacity: .75;
}

.search-placeholder__indexes {
    position: relative;
    font-family: var(--font-family-secondary);
    height: 1.25rem;

    &:after, &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 1;
    }

    &:before {
        top: -1rem;
        background: linear-gradient(180deg, var(--color-lightest), rgba(255, 255, 255, 0));
    }

    &:after {
        bottom: -1rem;
        background: linear-gradient(0deg, var(--color-lightest), rgba(255, 255, 255, 0));
    }
    
    ul {
        .list-clean();
        display: flex;
        flex-direction: column;
        gap: 2rem;
        animation: nextIndex 30s infinite ease-out;
    }

    li {
        display: flex;
        align-items: center;
        height: 1.25rem;
    }
}

@keyframes nextIndex {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    4% {
        transform: translateY(0);
        opacity: 1;
    }

    5% {
        transform: translateY(-3.25rem);
    }

    9% {
        transform: translateY(-3.25rem);
    }

    10% {
        transform: translateY(-6.5rem);
    }

    14% {
        transform: translateY(-6.5rem);
    }

    15% {
        transform: translateY(-9.75rem);
    }

    19% {
        transform: translateY(-9.75rem);
    }

    20% {
        transform: translateY(-13rem);
    }

    24% {
        transform: translateY(-13rem);
    }

    25% {
        transform: translateY(-16.25rem);
    }

    29% {
        transform: translateY(-16.25rem);
    }

    30% {
        transform: translateY(-19.5rem);
    }

    34% {
        transform: translateY(-19.5rem);
    }

    35% {
        transform: translateY(-22.75rem);
    }

    39% {
        transform: translateY(-22.75rem);
    }

    40% {
        transform: translateY(-26rem);
    }

    44% {
        transform: translateY(-26rem);
    }

    45% {
        transform: translateY(-29.25rem);
    }

    49% {
        transform: translateY(-29.25rem);
    }

    50% {
        transform: translateY(-32.5rem);
    }

    54% {
        transform: translateY(-32.5rem);
    }

    55% {
        transform: translateY(-35.75rem);
    }

    59% {
        transform: translateY(-35.75rem);
    }

    60% {
        transform: translateY(-39rem);
    }

    64% {
        transform: translateY(-39rem);
    }

    65% {
        transform: translateY(-42.25rem);
    }

    69% {
        transform: translateY(-42.25rem);
    }

    70% {
        transform: translateY(-45.5rem);
    }

    74% {
        transform: translateY(-45.5rem);
    }

    75% {
        transform: translateY(-48.75rem);
    }

    79% {
        transform: translateY(-48.75rem);
    }

    80% {
        transform: translateY(-52rem);
    }

    84% {
        transform: translateY(-52rem);
    }

    85% {
        transform: translateY(-55.25rem);
    }

    89% {
        transform: translateY(-55.25rem);
    }

    90% {
        transform: translateY(-58.5rem);
    }

    94% {
        transform: translateY(-58.5rem);
    }

    95% {
        transform: translateY(-61.75rem);
    }

    99% {
        transform: translateY(-61.75rem);
        opacity: 1;
    }

    99.5% {
        transform: translateY(-61.75rem);
        opacity: 0;
    }
    
    100% {
        transform: translateY(0);
        opacity: 0;
    }
}
</style>